<template>
  <div class="backTop" @click="backTop()">
    <i class="el-icon-caret-top" />
  </div>
</template>

<script>
export default {
  name: 'backTop',
  methods: {
    backTop() {
      this.$emit('success1')
    }
  }
}
</script>

<style lang="less" scoped></style>

<template>
  <div class="app-knowledge">
    <backTop @success="backTop()" v-show="IsBTop" :visiable="IsBTop" />
    <Breadcrumb class="custom-breadcrumb" ref="breadcrumb">
      <BreadcrumbItem>题库管理</BreadcrumbItem>
      <BreadcrumbItem>知识点</BreadcrumbItem>
    </Breadcrumb>

    <div class="app-content" v-infinite-scroll="setPage">
      <div :class="IsFixed ? 'app-left-fixed' : ''" class="knowledge-left">
        <h3 class="title">知识点&nbsp;({{ list.length }})</h3>
        <div class="knownTree" v-if="list.length > 0">
          <el-tree
            :data="list"
            style="padding: 10px 20px 0 20px;height: 100%"
            :class="treeClick ? 'click-forbid' : ''"
            node-key="id"
            :current-node-key="currentId"
            ref="knownTree"
            :expand-on-click-node="false"
            @node-click="handleClick"
            @node-expand="nodeExpand"
            @node-collapse="nodeCollapse"
            :default-expanded-keys="expandedList"
            highlight-current
          >
            <div slot-scope="{ node, data }" style="display: flex;font-size: 14px">
              <Tooltip :transfer="true" :content="data.name">
                <div class="knowLStyle">
                  {{ data.name.length > 8 ? data.name.substr(0, 8) + '...' : data.name }}
                </div>
              </Tooltip>

              <el-tooltip>
                <div slot="content">总题量: {{ data.questionCount }}<br />该知识点题量:{{ data.knowQuestionCount }}</div>
                <div style="color: #568FEB;margin-left: 8px">({{ data.questionCount }}- {{ data.knowQuestionCount }})</div>
              </el-tooltip>
            </div>
          </el-tree>
        </div>
      </div>
      <div style="font-size: 14px;overflow-y: auto;overflow-x: hidden" :class="IsFixed ? 'app-right-kFixed' : ''" id="qBank" class="app-right">
        <div class="app-topic" v-for="(t, tIdnex) in questionList" :key="tIdnex">
          <p class="qTypeS">[{{ t.questionType | qType }}]</p>
          <div class="stem" style="padding-right: 15px;word-break: keep-all;">
            {{ tIdnex + 1 }}、
            <!--             <p v-html="getcleanWord(t.stem)"/>-->
            <p v-html="t.stem" />
          </div>
          <div v-show="t.questionType !== 5">
            <div v-show="t.questionType === 1 || t.questionType === 2">
              <!--            // 选择题-->
              <p v-for="(o, oIndex) in t.options" :key="oIndex" style="display: flex;">
                {{ oIndex | charCode }}、<span class="spanPStyle" v-html="o.content" />
              </p>
              <!--                 {{getcleanWord(o.content)}}</p>-->
            </div>

            <div v-show="t.questionType === 0" style="text-align: left;">
              <!--            判断题-->
              <el-radio v-model="isCheck" disabled style="padding-left: 30px;margin-bottom: 20px" v-for="(r, rIndex) in 2" :key="rIndex"
                >{{ rIndex === 0 ? '正确' : '错误' }}
              </el-radio>
            </div>

            <p class="trueAnswer" style="color: #5ABC50;display: flex;flex-wrap: nowrap;">
              <span style="white-space: pre;">正确答案:</span><span v-html="t.questionAnswer || '暂无答案'" />
            </p>
            <div>
              <p>状态: &nbsp;&nbsp;{{ questionStatus[t.status] }}</p>
            </div>
            <div v-show="t.tags.length !== 0" class="tag">
              标签: &nbsp;&nbsp;
              <p v-for="(a, aIndex) in t.tags" :key="aIndex">{{ a.name }}</p>
            </div>
          </div>
          <div v-show="t.knowledgePoints" class="tag">
            知识点: &nbsp;&nbsp;
            <p v-for="(k, kIndex) in t.knowledgePoints" v-if="k.knowledgeName" :key="kIndex" style="color: #4579EA;background: #ECF2FC;">
              {{ k.knowledgeName }}
            </p>
            <p v-for="(k, kIndex) in t.knowledgePoints" v-if="k.name" :key="kIndex" style="color: #4579EA;background: #ECF2FC;">
              {{ k.name }}
            </p>
          </div>
          <!--           <div class="codeDiv" v-show="t.questionType === 5">-->
          <!--             <div>编写代码</div>-->
          <!--           </div>-->
          <div class="anlysis" v-show="t.analysis">
            解析: &nbsp;&nbsp;
            <p v-html="t.analysis" />
          </div>
          <hr style="height: 1px;border: none;background: #dedede;width: 100%;" />
          <div class="editorDiv">
            <div class="diff">
              难度:{{ t.difficulty | getDiff }} &nbsp;&nbsp;<el-divider style="padding: 0" direction="vertical" /> 预计完成时间:
              {{ t.expectedTime }}分钟
            </div>
            <div class="imgDiv">
              <p
                @click="editorQuestion(t, tIdnex)"
                @mouseenter="IsimgH = `1-${t.id || t.questionId}`"
                @mouseleave="IsimgH = 0"
                :style="IsimgH === `1-${t.id || t.questionId}` ? 'color: #4579EA' : ''"
              >
                <img v-if="IsimgH === `1-${t.id || t.questionId}`" width="15px" height="15px" src="../../../../assets/editorH.png" alt="" />
                <img v-else width="15px" height="15px" src="../../../../assets/编辑.png" alt="" />
                &nbsp;&nbsp;编辑
              </p>
              <p
                @click="delQuestion(t, tIdnex)"
                @mouseenter="IsimgH = `2-${t.id || t.questionId}`"
                @mouseleave="IsimgH = 0"
                :style="IsimgH === `2-${t.id || t.questionId}` ? 'color: #4579EA' : ''"
              >
                <img v-if="IsimgH === `2-${t.id || t.questionId}`" width="15px" height="15px" src="../../../../assets/shanchu.png" alt="" />
                <img v-else width="15px" height="15px" src="../../../../assets/删除.png" alt="" />
                &nbsp;&nbsp;删除
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--     ;reloadData()-->
    <QuestionAddNewModal
      :value="visible"
      ref="addQuestion"
      @on-change-visible="visible = false"
      :isQuestionAdd="isQuestionAdd"
      :questionBankIds="String(qBId)"
      :tags="tags"
      :page="listQuery.page"
      :size="listQuery.size"
      :modifyQuestionId="modifyQuestionId.toString()"
      @changeShowAddQuestionModal="changeShowAddQuestionModal"
      :knowledge-point-tree="knowledgePointTree"
      v-on:getQuestionForm="getQuestionForm"
    />
    <!--     <p v-if="loading" style="height: 40px;font-size: 16px;font-weight: bold;color: #4579EA">加载中...</p>-->
  </div>
</template>
<script>
import questionApi from '../../../../api/question'
import questionRepo from '../../../../api/questionRepo'
import knowledge from '../../../../api/knowledge'
import QuestionAddNewModal from '../../question/bank/question_handle/QuestionAddNewModal'
import backTop from '../../../common/backTop'
const defaultListQ = {
  sort: '',
  knowledgeId: null,
  searchContent: '',
  page: 0,
  size: 10,
  canPage: 0
}
const defaultModify = {
  modifyQuestionType: 0,
  modifyQuestionDifficulty: '',
  modifyQuestionKnowledgePoints: [],
  modifyQuestionExpectedTime: 0,
  modifyQuestionStem: '',
  modifyQuestionOptions: [],
  modifyQuestionBlanks: [],
  modifyQuestionAnswer: '',
  modifyQuestionAnalysis: '',
  questionId: 0,
  tags: []
}
export default {
  name: 'knowledge',
  components: { QuestionAddNewModal, backTop },
  data() {
    return {
      list: [],
      listQuery: Object.assign({}, defaultListQ),
      questionList: [],
      total: 0,
      qBId: 0,
      IsimgH: 0,
      topicList: [],
      tags: [],
      knowledgePointTree: [],
      selectNodeById: null,
      isCheck: '',
      modifyQuestionId: 0,
      isQuestionAdd: false,
      IsFixed: false,
      IsBTop: false,
      modifyForm: Object.assign({}, defaultModify),
      visible: false,
      loading: false,
      treeLoading: false,
      count: true,
      index: 0,
      currentId: 0,
      treeClick: false,
      expandedList: [], // 作为el-tree 的默认展开选项，在 数据刷新之后el-tree 会自动根据这个数据来展开对应选项
      questionStatus: ['已发布', '草稿', '待审核']
    }
  },
  created() {
    this.treeLoading = true
    this.getKnowLedge()
    this.getTags()
    // this.getKnowledgeTree()
  },
  filters: {
    qType(type) {
      switch (type) {
        case 0:
          return '判断题'
        case 1:
          return '单选题'
        case 2:
          return '多选题'
        case 3:
          return '填空题'
        case 4:
          return '简答题'
        case 5:
          return '代码题'
      }
    },
    charCode(num) {
      return String.fromCharCode(num + 65)
    },
    getDiff(type) {
      if (type === 0) return '简单'
      if (type === 1) return '一般'
      if (type === 2) return '困难'
    }
  },
  mounted() {
    window.addEventListener('scroll', this.IsBackTop, true)
  },
  methods: {
    getAllQuestion(page, size) {
      this.questionList = []
      this.getKnowLedge()
    },
    getKnowLedge() {
      questionApi
        .getKnownLedgeTree()
        .then(res => {
          this.sortData(res.res)
          this.list = res.res
          this.treeLoading = false
          if (this.currentId == 0) {
            this.currentId = this.list[0].id
            this.getFirst(this.list[0].id)
          } else {
            this.$nextTick(() => {
              this.$refs.knownTree.setCurrentKey(this.currentId)
            })
          }
        })
        .catch(() => {
          this.treeLoading = false
        })
    },
    getcleanWord(html) {
      return html
        .replace(/<[^>]+>/g, '')
        .replace(/&nbsp;/gi, '')
        .replace(/o:/g, '')
        .replace(/<font>/g, '')
        .replace(/<FONT>/g, '')
        .replace(/<span>/g, '')
        .replace(/<SPAN>/g, '')
        .replace(/<SPANlang=EN-US>/g, '')
        .replace(/<P>/g, '')
        .replace(/<\/P>/g, '')
        .replace(/<\/SPAN>/g, '')
        .replace(/<o:p>/g, '')
    },
    setPage() {
      window.scrollTo(0, 300)
      if (this.questionList.length === 0) return
      if (this.listQuery.page + 1 < this.listQuery.canPage) {
        this.loading = true
        this.listQuery.page += 1
        this.getQuestionByKnownId()
      }
      // else if (this.count) {
      //   this.$message({ message: '到底了', type: 'warning' })
      //   this.count = false
      // }
    },
    backTop() {
      let scrollById = document.querySelector('.app-content')
      scrollById.scrollTop = 0
    },
    IsBackTop() {
      let scrollById = document.querySelector('.app-content')
      if (scrollById) {
        if (scrollById.scrollTop >= 200) {
          this.IsBTop = true
          this.IsFixed = true
        } else {
          this.IsFixed = false
          this.IsBTop = false
        }
      }
    },
    getTags() {
      questionRepo.getTags().then(res => {
        this.tags = res.res
      })
    },
    changeShowAddQuestionModal(arg) {
      this.visible = arg
    },
    reloadData() {
      this.areaLoad('qBank')
      this.questionList = []
      this.getQuestionByKnownId()
    },
    areaLoad(elementName) {
      this.selectNodeById = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(220, 220, 220,0.7)',
        target: document.querySelector(`#${elementName}`)
      })
    },
    getFirst(id) {
      setTimeout(() => {
        this.areaLoad('qBank')
        this.listQuery.knowledgeId = id
        this.getQuestionByKnownId()
      }, 1000)
    },
    sortData(data) {
      this.knowledgePointTree = data.filter(item => {
        if (item.status === false) {
          return item
        }
      })
      data.forEach(d => {
        d.label = d.name
        if (data.children) {
          this.sortData(data.children)
        }
      })
    },
    handleClick(node) {
      if (this.treeClick) {
        return
      }
      this.treeClick = true
      window.scrollTo(0, 0)
      this.count = true
      this.questionList = []
      this.listQuery = Object.assign({}, defaultListQ)
      this.listQuery.knowledgeId = node.id
      this.currentId = node.id
      setTimeout(() => {
        this.areaLoad('qBank')
        this.getQuestionByKnownId()
      }, 500)
    },
    getQuestionByKnownId() {
      let a = this.listQuery
      questionApi.searchQuestionsByKnownOrTagId(a.knowledgeId, a.searchContent, a.sort, a.page, a.size, 1).then(res => {
        this.questionList = this.questionList.concat(res.res.data)
        this.total = res.res.total
        let total = res.res.total
        if (total !== 0) {
          this.listQuery.canPage = Number((total / this.listQuery.size + 1).toFixed(0))
        }
        this.loading = false
        this.selectNodeById.close()
        this.treeClick = false
      })
    },
    delQuestion(t, index) {
      this.$confirm('确定删除这道题目吗，不可恢复', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        //
        questionRepo.deleteQuestion(t.id).then(res => {
          if (res.res === true) {
            this.$message({ message: '成功', type: 'success' })
            this.questionList.splice(index, 1)
            this.getKnowLedge()
          } else {
            // this.$message({ message: `删除失败,${res.res}`, type: 'warning' })
          }
        })
      })
    },
    editorQuestion(t, tIdnex) {
      this.qBId = t.questionBankId
      this.modifyQuestionId = t.id || t.questionId
      this.isQuestionAdd = false
      questionRepo.getQuestionInfo(t.id || t.questionId).then(res => {
        this.modifyForm.modifyQuestionType = res.res.questionType
        this.modifyForm.modifyQuestionDifficulty = res.res.difficulty
        this.modifyForm.modifyQuestionExpectedTime = res.res.expectedTime
        this.modifyForm.modifyQuestionStem = res.res.stem
        this.modifyForm.modifyQuestionAnswer = res.res.answer
        this.modifyForm.modifyQuestionAnalysis = res.res.analysis
        this.modifyForm.modifyQuestionOptions = res.res.options
        this.modifyForm.modifyQuestionBlanks = res.res.blanks
        this.modifyForm.modifyQuestionCodeTemplate = res.res.template
        this.modifyForm.modifyQuestionKnowledgePoints = res.res.knowledgePoints || []
        this.modifyForm.tags = res.res.tags
        this.$refs.addQuestion.getData(this.modifyForm)
        this.index = tIdnex
        this.visible = true
      })
    },
    getQuestionForm() {
      questionRepo.getQuestionInfo(this.modifyQuestionId).then(res => {
        this.$set(this.questionList, this.index, res.res)
      })
    },
    nodeExpand(data) {
      this.expandedList.push(data.id) // 在节点展开是添加到默认展开数组
    },
    nodeCollapse(data) {
      this.expandedList.splice(this.expandedList.indexOf(data.id), 1) // 收起时删除数组里对应选项
    }
  }
}
</script>
<style lang="less" scoped>
.click-forbid {
  pointer-events: none;
  cursor: no-drop !important;
}
.app-right {
  max-height: 100%;
}
.app-topic:last-child {
  margin-bottom: 0;
}
</style>
